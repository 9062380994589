import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getSingleDevice, updateSingleDevice, createSingleDevice, getPlaylistsThunk, cancelSubmitDevice, getPlaylistSuccess } from '../store'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { history } from '../router/AppRouter'
import { RingLoader } from 'react-spinners'
import { css } from '@emotion/core';
import supportedDeviceTypes from '../utility/utility'

const override = css`
display: block;
margin: 0 auto;
`;

class DeviceEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: '',
            dateCreated: '',
            dateModified: '',
            type: 'CINEMA_KIOSK',
            createdBy: '',
            playlists: [],
            storedPlaylists: [],
            loading: true,
            modal: false,
            messageHeader: '',
            messageBody: '',
            ...this.props.location.state,
        }

        this.nameChangeHandler = this.nameChangeHandler.bind(this);
        this.typeChangeHandler = this.typeChangeHandler.bind(this);
        this.submitDevice = this.submitDevice.bind(this);
        this.removePlaylist = this.removePlaylist.bind(this);
        this.alreadyAddedFilter = this.alreadyAddedFilter.bind(this);
        this.addPlaylist = this.addPlaylist.bind(this);
        this.descriptionChangeHandler = this.descriptionChangeHandler.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.checkForError = this.checkForError.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount() {
        this.props.getPlaylists();
        if (this.state.key === undefined) {
            this.props.stopLoading();
            //Undefined key means that this is a request to create a new playlist
            this.setState({
                action: 'CREATE',
                ...this.state
            })
        } else {
            this.props.getSingleDevice(this.state.key);
            this.setState({
                action: 'UPDATE',
                ...this.state
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        this.setState({
            ...nextProps
        }, () => {
            this.checkForError();
        })
    }

    checkForError() {
        if (this.state.error) {
            this.toggle();
        }
    }

    nameChangeHandler(event) {
        this.setState({
            name: event.target.value
        });
    }

    descriptionChangeHandler(event) {
        this.setState({
            description: event.target.value
        });
    }

    typeChangeHandler(event) {
        this.setState({
            type: event.target.value
        })
    }

    parseDate(date) {
        if (date === undefined || date.length === 0) {
            return 'N/A'
        } else {
            return new Date(date).toLocaleString()
        }
    }

    submitDevice(e) {
        e.preventDefault();
        console.log('Performing ', this.state.action);
        const { name, key, description, playlists, createdBy, dateCreated, type } = this.state;
        if (this.state.action === 'UPDATE') {
            const device = {};
            device.name = name;
            device.key = key;
            device.description = description
            device.type = type;
            device.createdBy = createdBy;
            device.dateCreated = dateCreated;
            device.playlists = playlists;
            this.props.updateDevice(device)

            console.log('Submitting update request: ', device)
        } else if (this.state.action === 'CREATE') {
            const device = {};
            device.name = name;
            device.description = description
            device.type = type;
            device.playlists = playlists;
            this.props.createDevice(device);
        }

        history.push('/device-manager');
    }

    removePlaylist(key) {
        let playlists = this.state.playlists;
        for (var i = playlists.length - 1; i >= 0; i--) {
            if (playlists[i].key === key) {
                playlists.splice(i, 1);
            }
        }
        this.setState({
            playlists: playlists
        });
    }

    addPlaylist(playlist) {
        let playlists = this.state.playlists;
        let newPriority = playlists.length === 0 ? 1 : playlists[playlists.length - 1].priority + 1;
        playlists.push({ priority: newPriority, ...playlist });

        this.setState({
            playlists: playlists
        })
    }

    alreadyAddedFilter(value) {
        return !this.state.playlists.some(e => e.key === value.key);
    }

    renderForm() {
        if (!this.state.loading) {
            return (<Col md='12'>
                <Form onSubmit={this.submitDevice}>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input type='text' name='name' id='deviceNameInput' value={this.state.name} onChange={this.nameChangeHandler} required />
                    </FormGroup>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input type='text' name='description' id='deviceDescriptionInput' value={this.state.description} onChange={this.descriptionChangeHandler} required />
                    </FormGroup>
                    <FormGroup>
                        <Label>Device Type</Label>
                        <Input type='select' name='type' id='deviceTypeInput' onChange={this.typeChangeHandler} required value={this.state.type}>
                            {Object.keys(supportedDeviceTypes).map((key, index) => (
                                <option key={index} value={supportedDeviceTypes[key].value}>
                                    {supportedDeviceTypes[key].name}
                                </option>))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Date Created</Label>
                        <Input type='text' disabled name='dateCreated' id='schedulerExpression' value={this.parseDate(this.state.dateCreated)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Last modified</Label>
                        <Input type='text' disabled name='dateModified' id='schedulerExpression' value={this.parseDate(this.state.dateModified)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Created By</Label>
                        <Input type='text' disabled name='createdBy' id='schedulerExpression' value={this.state.createdBy} />
                    </FormGroup>
                    <FormGroup>
                        <h4>Playlists</h4>
                        <ListGroup>
                            {this.state.playlists.map(playlist => {
                                return (
                                    <ListGroupItem key={playlist.key}><span>{playlist.priority}. {playlist.name}</span> <i className="fas fa-times fa-2x float-right" style={{ color: 'red' }} onClick={() => this.removePlaylist(playlist.key)} /></ListGroupItem>
                                )
                            }
                            )}
                        </ListGroup>
                    </FormGroup>
                    <FormGroup>
                        <h4>Available Playlists</h4>
                        <ListGroup>
                            {this.state.storedPlaylists && this.props.storedPlaylists.filter(this.alreadyAddedFilter).map(playlist => {
                                return (
                                    <ListGroupItem key={playlist.key}><span>{playlist.name}</span> <i className="fas fa-plus fa-2x float-right" style={{ color: 'green' }} onClick={() => this.addPlaylist(playlist)} /></ListGroupItem>
                                )
                            }
                            )}
                        </ListGroup>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: '/device-manager' }} onClick={() => this.props.cancelSubmitDevice()}>
                            <Button className={classNames('bg-danger', 'm-2')}>Cancel</Button>
                        </Link>
                        <Button type='submit' className={classNames('bg-success', 'm-2')}>Submit</Button>
                    </FormGroup>
                </Form>
            </Col>);
        }
    }

    render() {
        return (
            <Container fluid className="px-5 col-md-6 offset-md-3">
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.messageHeader}</ModalHeader>
                    <ModalBody>
                        {this.state.messageBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => history.push('/device-manager')}>Go back</Button>{' '}
                    </ModalFooter>
                </Modal>
                <Row className='py-3'>
                    <Col>
                        <h2>Device Editor</h2>
                    </Col>
                </Row>
                <Row className='py-3'>
                    <RingLoader css={override} size={250} loading={this.state.loading} color={'#3381D7'} />
                    {this.renderForm()}
                </Row>
            </Container>
        );
    }
}

const mapState = function (state) {
    console.log(state);
    return {
        ...state.currentDevice,
        storedPlaylists: state.playlists,
        loading: state.loading,
        error: state.error,
        messageBody: state.messageBody,
        messageHeader: state.messageHeader
    }
}

const mapDispatch = dispatch => {
    return {
        stopLoading: () => dispatch(getPlaylistSuccess()),
        getPlaylists: () => dispatch(getPlaylistsThunk()),
        getSingleDevice: (key) => dispatch(getSingleDevice(key)),
        updateDevice: (playlist) => dispatch(updateSingleDevice(playlist)),
        createDevice: (playlist) => dispatch(createSingleDevice(playlist)),
        cancelSubmitDevice: () => dispatch(cancelSubmitDevice())
    }
}

export default connect(mapState, mapDispatch)(DeviceEditor);