import React, { Component } from 'react';
import { Button } from 'reactstrap'
import logo from '../assets/logo.png';
import { connect } from 'react-redux';
import {firebaseLogout} from '../store'

class Header extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h1 className="App-title my-3">Welcome to the Video Signage CMS &#945;</h1>
                    <h6>{this.props.user.displayName} ({this.props.user.email})</h6>
                    <Button onClick={this.props.logout}>Logout</Button>
                </header>
            </div>)
    }
}

function mapState(state) {
    const props = { user: state.user };
    return props;
}

const mapDispatch = dispatch => {
    return {
        logout: () => dispatch(firebaseLogout())
    }
}

export default connect(mapState, mapDispatch)(Header);
