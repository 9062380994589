import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header'
import { bubble as Menu } from 'react-burger-menu'
import { Container } from 'reactstrap'

const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    ...otherProps
}) => (
        <Route {...otherProps} component={(props) => {
            if (isAuthenticated) {
                return (
                    <Container fluid className='p-0'>
                        <Menu width={350}>
                            <a id="device-manager" className="menu-item" href="/device-manager"><i className="fas fa-tv"></i><span>Display Device Manager</span></a>
                            <a id="playlist-manager" className="menu-item" href="/playlist-manager"><i className="fas fa-list"></i><span>Playlist Manager</span></a>
                            <a id="video-manager" className="menu-item" href="/video-manager"><i className="fas fa-video"></i><span>Video Manager</span></a>
                        </Menu>
                        <Header />
                        <Component {...props} />
                    </Container>
                );
            } else {
                return (
                    <Redirect to='/' />
                );
            }
        }} />
    );

const mapStateToProps = function (state) {
    return {
        isAuthenticated: !!state.user
    }
}

export default connect(mapStateToProps)(PrivateRoute);