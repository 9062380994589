import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Card, CardBody, CardTitle, Row,
    CardHeader, CardSubtitle, Button
} from 'reactstrap'
import cronstrue from 'cronstrue'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { deleteDevice } from '../store'
import supportedDeviceTypes from '../utility/utility'

class DeviceGridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleted: false,
        };
    }

    deleteDevice = (key) => {
        this.setState({
            deleted: true
        });
        this.props.deleteDeviceFromFirebase(key);
    }

    parseCronStatement = (cron) => {
        try {
            return cronstrue.toString(cron);
        } catch (error) {
            console.log(error);
            return 'Invalid cron statement';
        }
    }

    render() {
        return (
            <Card className={classNames(this.state.deleted ? 'bg-danger' : '', this.props.content.placeholder ? 'bg-secondary' : '', 'h-100')}>
                <CardHeader tag='h3'>{this.props.content.name}</CardHeader>
                <CardBody>
                    <CardTitle>{this.props.content.description}</CardTitle>
                    <CardSubtitle>{this.props.content.playlists ? this.props.content.playlists.length : 0} playlist(s)</CardSubtitle>
                    <ul className='list-unstyled'>
                        <li><b>Type:</b> {supportedDeviceTypes[this.props.content.type].name}</li>
                        <li><b>Created by:</b> {this.props.content.createdBy}</li>
                        <li><b>Date modified:</b> {new Date(this.props.content.dateModified).toDateString()}</li>
                        <li><b>Date created:</b> {new Date(this.props.content.dateCreated).toDateString()}</li>
                    </ul>
                    <Row>
                        <Link to={{ pathname: '/device-editor', state: { key: this.props.content.key } }}>
                            <Button disabled={this.props.content.placeholder || this.state.deleted} className={classNames('bg-primary', 'm-2')}>View</Button>
                        </Link>
                        <Button disabled={this.props.content.placeholder || this.state.deleted} onClick={() => this.deleteDevice(this.props.content.key)} className={classNames('bg-danger', 'm-2')}>Delete</Button>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}
const mapDispatch = dispatch => {
    return {
        deleteDeviceFromFirebase: (key) => dispatch(deleteDevice(key)),
    }
}

export default connect(undefined, mapDispatch)(DeviceGridItem)