import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Card, CardBody, Row,
    CardHeader, CardSubtitle, Button
} from 'reactstrap'
import cronstrue from 'cronstrue'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { deletePlaylist } from '../store'

class PlaylistGridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleted: false,
        };
    }

    deletePlaylist = (key) => {
        this.setState({
            deleted: true
        });
        this.props.deletePlaylistFromFirebase(key);
    }

    parseCronStatement = (cron) => {
        try {
            return cronstrue.toString(cron);
        } catch (error) {
            console.log(error);
            return 'Invalid cron statement';
        }
    }

    render() {
        return (
            <Card className={classNames(this.state.deleted ? 'bg-danger' : '', this.props.content.placeholder ? 'bg-secondary' : '', 'h-100')}>
                <CardHeader tag='h3'>{this.props.content.name}</CardHeader>
                <CardBody>
                    <CardSubtitle>{this.props.content.videos ? this.props.content.videos.length : 0} videos(s)</CardSubtitle>
                    <ul className='list-unstyled'>
                        <li>Schedule: {this.parseCronStatement(this.props.content.cron)}</li>
                        <li>Created by: {this.props.content.createdBy}</li>
                        <li>Date modified: {new Date(this.props.content.dateModified).toDateString()}</li>
                        <li>Date created: {new Date(this.props.content.dateCreated).toDateString()}</li>
                    </ul>
                    <Row>
                        <Link to={{ pathname: '/playlist-editor', state: { key: this.props.content.key } }}>
                            <Button className={classNames('bg-primary', 'm-2')}>View</Button>
                        </Link>
                        <Button onClick={() => this.deletePlaylist(this.props.content.key)} className={classNames('bg-danger', 'm-2')}>Delete</Button>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}
const mapDispatch = dispatch => {
    return {
        deletePlaylistFromFirebase: (key) => dispatch(deletePlaylist(key)),
    }
}

export default connect(undefined, mapDispatch)(PlaylistGridItem)