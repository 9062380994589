import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Card, CardText,
    CardTitle, Button
} from 'reactstrap'

class AddNewGridItem extends Component {
    render() {
        return (
            <Card body outline className='h-100'>
                <CardTitle>{this.props.title}</CardTitle>
                <CardText>{this.props.text}</CardText>
                <Link to={this.props.link} className='mt-auto'>
                    <Button className='btn-block'>{this.props.buttonText}</Button>
                </Link>
            </Card>
        )
    }
}

export default AddNewGridItem