import React, { Component } from 'react';
import { removeVideoFromFirebase, getLinkFromFireBase } from '../firebase'
import {
    Card, CardBody, Row,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import classNames from 'classnames'

class VideoGridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleted: false,
        };
    }

    async componentDidMount() {
        let downloadLink = await getLinkFromFireBase(this.props.content.name);
        this.setState({
            ...this.state,
            downloadLink: downloadLink
        })
    }

    deleteVideo = () => {
        this.setState({
            deleted: true
        });
        removeVideoFromFirebase(this.props.content.name);
    }

    parseFileType = (contentType) => {
        let result = contentType.indexOf('/');

        if (result !== -1) {
            return contentType.substring(result + 1) + ' video';
        } else {
            return contentType;
        }
    }

    formatBytes = (bytes, decimals) => {
        if (bytes === 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    render() {
        return (
            <Card key={this.key} className={classNames(this.state.deleted ? 'bg-danger' : '', this.props.content.placeholder ? 'bg-secondary' : '')}>
                <CardBody>
                    <CardTitle>{this.props.content.name}</CardTitle>
                    <CardSubtitle>{this.parseFileType(this.props.content.contentType)}</CardSubtitle>
                    <ul className='list-unstyled'>
                        <li>Time updated: {new Date(this.props.content.updated).toLocaleString('ja-JP')}</li>
                        <li>Size: {this.formatBytes(this.props.content.size)}</li>
                    </ul>
                    <Row>
                        <a href={this.state.downloadLink} download={this.props.content.name}>
                            <Button className={classNames('bg-primary', 'm-2')}>View</Button>
                        </a>
                        <Button onClick={this.deleteVideo} className={classNames('bg-danger', 'm-2')}>Delete</Button>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

export default (VideoGridItem)