import React, { Component } from 'react';
import '../App.css';
import { storage } from '../firebase'
import { connect } from 'react-redux'
import { videoDatabaseChangedEvent } from '../store'
import GenericGrid from '../components/GenericGrid'
import VideoGridItem from '../components/VideoGridItem'
import FileUploader from 'react-firebase-file-uploader'
import { Form, FormGroup, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

class VideoManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      messageHeader: '',
      messageBody: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0, modal: true, messageHeader: 'Uploading', messageBody: 'Upload in progress... 0%' })
  };

  handleProgress = progress => this.setState({ messageBody: 'Upload in progress... ' + progress + '%' });

  handleUploadError = error => {
    this.setState({ isUploading: false, messageBody: 'An error has occurred: ' + error.message });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    let videos = this.props.videos;
    let tmpVid = {
      placeholder: true,
      contentType: 'video/mp4',
      id: 'placeholder'
    }
    videos.push(tmpVid);
    this.setState({
      progress: 100,
      isUploading: false,
      modal: false,
      videos: videos
    });

    document.getElementById('file-upload-form').reset();
  }

  componentDidMount() {
    this.props.subscribe()
  }

  render() {
    return (
      <div>
        <Container fluid className="py-2 px-5">
          <Row>
            <Col>
              <Form id='file-upload-form'>
                <FormGroup>
                  <h4>Upload a new video</h4>
                  <FileUploader accept="video/mp4,video/x-m4v,video/*" name="videoFileName" storageRef={storage.ref()}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress} />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        <GenericGrid title="Videos" videos={this.props.videos}>
          {this.props.videos.map((video) => (
            <Col sm="6" md="4" className="my-2" key={video.id}>
              <VideoGridItem key={video.id} content={video} />
            </Col>
          ))}</GenericGrid>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.state.messageHeader}</ModalHeader>
          <ModalBody>
            {this.state.messageBody}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapState(state) {
  const props = {
    videos: state.videos
  };
  console.log(props);
  return props;
}

const mapDispatch = dispatch => {
  return {
    subscribe: () => dispatch(videoDatabaseChangedEvent()),
  }
}
export default connect(mapState, mapDispatch)(VideoManager);
