import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter, { history } from './router/AppRouter'
import { registerServiceWorker, unregister} from './registerServiceWorker';
import store from './store'
import { Provider } from 'react-redux'
import { firebaseApp } from './firebase'
import { login, logout, firebaseLogout } from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config'

const template = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

let isRendered = false;
const renderApp = () => {
  if (!isRendered) {
    ReactDOM.render(template, document.getElementById('root'));
    isRendered = true;
  }
}

firebaseApp.auth().onAuthStateChanged(user => {
  if (user) {
    console.log('login user id: ', user.uid);
    console.log('name: ', user.displayName);

    //Get the token 
    user.getIdTokenResult()
      .then((result) => {
        //Check if the whitelist/claims have been changed on the backend
        return fetch(config.claimsEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            idToken: result.token,
          })
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
          console.log('Request succeeded!', response);
        } else {
          throw new Error('Unexpected http response ' + response.status + ' from ' + config.claimsEndpoint)
        }
      })
      .then(() => {
        //Get the token again to see if the claims have been updated on the server
        return user.getIdTokenResult(true)
      })
      .then(result => {
        if (result.claims.whitelisted) {
          store.dispatch(login(user));
          renderApp();
          if (history.location.pathname === '/') {
            history.push('/video-manager');
          }
        } else {
          //Non whitelisted users are automatically rejected
          store.dispatch(firebaseLogout());
          store.dispatch(logout());
          history.push('/');
          renderApp();
          console.log('logout');
          alert('Sorry, this user is not authorized to use this service.');
        }
      })
      .catch(error => {
        if (error.message) console.log(error.message);
        console.log(error);
      })
  } else {
    store.dispatch(logout());
    history.push('/');
    renderApp();
    console.log('logout');
  }
});

unregister();