import React, { Component } from 'react'
import '../App.css';
import { connect } from 'react-redux'
import { playlistDatabaseChangedEvent } from '../store'
import GenericGrid from '../components/GenericGrid'
import PlaylistGridItem from '../components/PlaylistGridItem'
import AddNewGridItem from '../components/AddNewGridItem'
import { Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap'

class PlaylistManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            messageHeader: '',
            messageBody: ''
        };
    }

    componentDidMount() {
        this.props.subscribe()
    }

    messageDisplay(status, message) {
        return (
            <Alert color={status === 'success' ? 'success' : 'danger'} fade>{message}</Alert>
        )
    }

    render() {
        return (
            <div>
                {this.props.status && this.messageDisplay(this.props.status, this.props.message)}
                <GenericGrid title="Playlists">
                    {this.props.playlists.map((playlist) => (
                        <Col sm="6" md="4" className="my-2" key={playlist.key}>
                            <PlaylistGridItem key={playlist.key} content={playlist} />
                        </Col>
                    ))}
                    <Col sm="6" md="4" className="my-2">
                        <AddNewGridItem title='Add new playlist' text='Create a new playlist to organize your videos' buttonText='Add new playlist' link='/playlist-editor' />
                    </Col>
                </GenericGrid>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.messageHeader}</ModalHeader>
                    <ModalBody>
                        {this.state.messageBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

function mapState(state) {
    const props = {
        status: state.status,
        message: state.message,
        playlists: state.playlists
    };
    return props;
}

const mapDispatch = dispatch => {
    return {
        subscribe: () => dispatch(playlistDatabaseChangedEvent()),
    }
}
export default connect(mapState, mapDispatch)(PlaylistManager);
