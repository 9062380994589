import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'

class GenericGrid extends Component {
    render() {
        return (
            <Container fluid className="py-2 px-5">
                <Row>
                    <Col>
                        <h2>{this.props.title}</h2>
                    </Col>
                </Row>
                <Row>
                    {this.props.children}
                </Row>
            </Container>
        )
    }
}

export default (GenericGrid)
