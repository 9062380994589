import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import config from './config'

export const firebaseApp = firebase.initializeApp(config);
export const database = firebase.database();
export const storage = firebase.storage();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

//The upload function is accomplished use the firebase-file-uploader component, so there is no method here

/**
 * Deletes the designated file from google cloud storage
 * @param {the full name in Google cloud storage} name 
 */
export const removeVideoFromFirebase = (name) => {
    let storageRef = storage.ref().child(name);
    
    storageRef.delete().then(() => {
      console.log('File deleted successfully');
    }).catch(() => {
      console.log('File delete failed');
    })
}

export const getLinkFromFireBase = async (name) => {
  let link =  await storage.ref().child(name).getDownloadURL();
  return link;
}