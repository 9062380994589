import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, FormText, Label, Input, Container, Row, Col, ListGroup, ListGroupItem, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getSinglePlaylist, updateSinglePlaylist, createSinglePlaylist, getVideosThunk, cancelSubmitPlaylist, getPlaylistSuccess } from '../store'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import cronstrue from 'cronstrue'
import { history } from '../router/AppRouter'
import { RingLoader } from 'react-spinners'
import { css } from '@emotion/core';

const override = css`
display: block;
margin: 0 auto;
`;

class PlaylistEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            cron: '',
            parsedCronStatement: '',
            validate: {
                cronState: ''
            },
            dateCreated: '',
            dateModified: '',
            createdBy: '',
            videos: [],
            loading: true,
            storedVideos: [],
            modal: false,
            messageHeader: '',
            messageBody: '',
            ...this.props.location.state,
        }

        this.nameChangeHandler = this.nameChangeHandler.bind(this);
        this.cronChangeHandler = this.cronChangeHandler.bind(this);
        this.submitPlaylist = this.submitPlaylist.bind(this);
        this.removeVideo = this.removeVideo.bind(this);
        this.alreadyAddedFilter = this.alreadyAddedFilter.bind(this);
        this.addVideo = this.addVideo.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.checkForError = this.checkForError.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount() {
        this.props.getVideos();
        if (this.state.key === undefined) {
            this.props.stopLoading();
            //Undefined key means that this is a request to create a new playlist
            this.setState({
                action: 'CREATE',
                ...this.state
            })
        } else {
            this.props.getSinglePlaylist(this.state.key);
            this.setState({
                action: 'UPDATE',
                ...this.state
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        this.setState({
            ...nextProps,
        }, () => {
            this.checkForError();
            this.validateCronStatement()
        })
    }

    checkForError() {
        if (this.state.error) {
            this.toggle();
        }
    }

    nameChangeHandler(event) {
        this.setState({
            name: event.target.value
        });
    }

    cronChangeHandler(event) {
        this.setState({
            cron: event.target.value
        }, () => this.validateCronStatement());
    }

    validateCronStatement = () => {
        try {
            this.setState({
                parsedCronStatement: cronstrue.toString(this.state.cron),
                validate: {
                    cronState: 'has-success'
                }
            })
        } catch (error) {
            console.log(error);
            this.setState({
                validate: {
                    cronState: 'has-danger'
                }
            })
        }
    }

    parseDate(date) {
        if (date === undefined || date.length === 0) {
            return 'N/A'
        } else {
            return new Date(date).toLocaleString()
        }
    }

    submitPlaylist(e) {
        e.preventDefault();
        console.log('Performing ', this.state.action);
        const { name, key, cron, videos, createdBy, dateCreated } = this.state;
        if (this.state.action === 'UPDATE') {
            const playlist = {};

            playlist.name = name;
            playlist.key = key;
            playlist.cron = cron;
            playlist.videos = videos;
            playlist.createdBy = createdBy;
            playlist.dateCreated = dateCreated;
            this.props.updatePlaylist(playlist)

            console.log('Submitting update request: ', playlist)
        } else if (this.state.action === 'CREATE') {
            const playlist = {};
            playlist.name = name;
            playlist.cron = cron;
            playlist.videos = videos;
            this.props.createPlaylist(playlist);
        }

        history.push('/playlist-manager');
    }

    removeVideo(key) {
        let videos = this.state.videos;
        for (var i = videos.length - 1; i >= 0; i--) {
            if (videos[i].key === key) {
                videos.splice(i, 1);
            }
        }
        this.setState({
            videos: videos
        });
    }

    addVideo(video) {
        let videos = this.state.videos;
        let newOrder = videos.length === 0 ? 1 : videos[videos.length - 1].order + 1;
        videos.push({ order: newOrder, ...video });

        this.setState({
            videos: videos
        })
    }

    alreadyAddedFilter(value) {
        return !this.state.videos.some(e => e.key === value.key);
    }

    renderForm() {
        if (!this.state.loading) {
            return (
                <Col md='12'>
                    <Form onSubmit={this.submitPlaylist}>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input type='text' name='name' id='playlistNameInput' value={this.state.name} onChange={this.nameChangeHandler} required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Scheduler expression (cron)</Label>
                            <Input type='text' name='schedulerExpression' id='schedulerExpression' value={this.state.cron} onChange={this.cronChangeHandler}
                                valid={this.state.validate.cronState === 'has-success'} invalid={this.state.validate.cronState === 'has-danger'} required>
                            </Input>
                            <FormFeedback valid>
                                Executes: {this.state.parsedCronStatement}
                            </FormFeedback>
                            <FormFeedback invalid>
                                Invalid cron statement.
                                    </FormFeedback>
                            <FormText>This is a cron expression <a href='https://docs.oracle.com/cd/E12058_01/doc/doc.1014/e12030/cron_expressions.htm'>See here!</a></FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label>Date Created</Label>
                            <Input type='text' disabled name='dateCreated' id='schedulerExpression' value={this.parseDate(this.state.dateCreated)} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last modified</Label>
                            <Input type='text' disabled name='dateModified' id='schedulerExpression' value={this.parseDate(this.state.dateModified)} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Created By</Label>
                            <Input type='text' disabled name='createdBy' id='schedulerExpression' value={this.state.createdBy} />
                        </FormGroup>
                        <FormGroup>
                            <h4>Videos</h4>
                            <ListGroup>
                                {this.state.videos.map(video => {
                                    return (
                                        <ListGroupItem key={video.key}><span>{video.order}. {video.name}</span> <i className="fas fa-times fa-2x float-right" style={{ color: 'red' }} onClick={() => this.removeVideo(video.key)} /></ListGroupItem>
                                    )
                                }
                                )}
                            </ListGroup>
                        </FormGroup>
                        <FormGroup>
                            <h4>Available videos</h4>
                            <ListGroup>
                                {this.state.storedVideos && this.props.storedVideos.filter(this.alreadyAddedFilter).map(video => {
                                    return (
                                        <ListGroupItem key={video.key}><span>{video.name}</span> <i className="fas fa-plus fa-2x float-right" style={{ color: 'green' }} onClick={() => this.addVideo(video)} /></ListGroupItem>
                                    )
                                }
                                )}
                            </ListGroup>
                        </FormGroup>
                        <FormGroup>
                            <Link to={{ pathname: '/playlist-manager' }} onClick={() => this.props.cancelSubmitPlaylist()}>
                                <Button className={classNames('bg-danger', 'm-2')}>Cancel</Button>
                            </Link>
                            <Button type='submit' className={classNames('bg-success', 'm-2')}>Submit</Button>
                        </FormGroup>
                    </Form>
                </Col>
            );
        }
    }

    render() {
        return (
            <Container fluid className="px-5 col-md-6 offset-md-3">
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.messageHeader}</ModalHeader>
                    <ModalBody>
                        {this.state.messageBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => history.push('/playlist-manager')}>Go back</Button>{' '}
                    </ModalFooter>
                </Modal>
                <Row className='py-3'>
                    <Col>
                        <h2>Playlist Editor</h2>
                    </Col>
                </Row>
                <Row className='py-3'>
                    <RingLoader css={override} size={250} loading={this.state.loading} color={'#3381D7'} />
                    {this.renderForm()}
                </Row>
            </Container>
        );
    }
}

const mapState = function (state) {
    return {
        ...state.currentPlaylist,
        storedVideos: state.videos,
        loading: state.loading,
        error: state.error,
        messageBody: state.messageBody,
        messageHeader: state.messageHeader
    }
}

const mapDispatch = dispatch => {
    return {
        stopLoading: () => dispatch(getPlaylistSuccess()),
        getVideos: () => dispatch(getVideosThunk()),
        getSinglePlaylist: (key) => dispatch(getSinglePlaylist(key)),
        updatePlaylist: (playlist) => dispatch(updateSinglePlaylist(playlist)),
        createPlaylist: (playlist) => dispatch(createSinglePlaylist(playlist)),
        cancelSubmitPlaylist: () => dispatch(cancelSubmitPlaylist())
    }
}

export default connect(mapState, mapDispatch)(PlaylistEditor);