import React, { Component } from 'react'
import '../App.css';
import { connect } from 'react-redux'
import { deviceDatabaseChangedEvent } from '../store'
import GenericGrid from '../components/GenericGrid'
import DeviceGridItem from '../components/DeviceGridItem'
import AddNewGridItem from '../components/AddNewGridItem'
import { Col, Alert } from 'reactstrap'

class DeviceManager extends Component {
    componentDidMount() {
        this.props.subscribe()
    }

    messageDisplay(status, message) {
        return (
            <Alert color={status === 'success' ? 'success' : 'danger'} fade>{message}</Alert>
        )
    }

    render() {
        return (
            <div>
                {this.props.status && this.messageDisplay(this.props.status, this.props.message)}
                <GenericGrid title="Devices">
                    {this.props.devices.map((device) => (
                        <Col sm="6" md="4" className="my-2" key={device.key}>
                            <DeviceGridItem key={device.key} content={device} />
                        </Col>
                    ))}
                    <Col sm="6" md="4" className="my-2">
                        <AddNewGridItem title='Add new Device' text='Create a new device that will play a number of different playlists' buttonText='Add new device' link='/device-editor' />
                    </Col>
                </GenericGrid>
            </div>
        );
    }
}

function mapState(state) {
    const props = {
        status: state.status,
        message: state.message,
        devices: state.devices
    };
    return props;
}

const mapDispatch = dispatch => {
    return {
        subscribe: () => dispatch(deviceDatabaseChangedEvent()),
    }
}
export default connect(mapState, mapDispatch)(DeviceManager);
