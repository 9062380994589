import React from 'react';
import { connect } from 'react-redux';
import { firebaseLogin } from '../store';
import logo from '../assets/logo.png';
import normalButton from '../assets/btn_google_signin_light_normal_web@2x.png'

const Login = ({ login }) => (

    <div className='row m-0 h-100 justify-content-center align-items-center'>
        <div className='loginCard p-5'>
            <img src={logo} className="logo" alt="logo" />
            <h3>Video Signage CMS</h3>
            <input type="image" src={normalButton} onClick={login} alt="Login with google"></input>
        </div>
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    login: () => dispatch(firebaseLogin())
});

export default connect(undefined, mapDispatchToProps)(Login);