export default {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    claimsEndpoint: process.env.REACT_APP_CLAIMS_ENDPOINT,
    playlistsEndpoint: process.env.REACT_APP_PLAYLISTS_ENDPOINT,
    devicesEndpoint: process.env.REACT_APP_DEVICES_ENDPOINT
}