import React from 'react';
import { Router, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Login from '../components/LoginPage';
import VideoManager from '../routes/VideoManager'
import PlaylistManager from '../routes/PlaylistManager';
import PlaylistEditor from '../routes/PlaylistEditor';
import DeviceManager from '../routes/DeviceManager';
import DeviceEditor from '../routes/DeviceEditor';

export const history = createHistory();

const AppRouter = () => (
    <Router history={history}>
        <div className='h-100'>
            <Switch>
                <PrivateRoute path='/device-editor' component={DeviceEditor} />
                <PrivateRoute path='/device-manager' component={DeviceManager} />
                <PrivateRoute path='/playlist-editor' component={PlaylistEditor} />
                <PrivateRoute path='/video-manager' component={VideoManager} />
                <PrivateRoute path='/playlist-manager' component={PlaylistManager} />
                <PublicRoute path='/' component={Login} />
            </Switch>
        </div>
    </Router>
);

export default AppRouter;