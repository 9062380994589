import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { firebaseApp, googleAuthProvider, database } from './firebase'
import config from './config'

//Usually we would have separate reducer files and combine them in a store index file. This application will be a simple one, so we will just put everything in one file for simplicity sake.

const INTIAL_STATE = {
  uid: '',
  videos: [],
  playlists: [],
  devices: []
}
/**
 * ACTION TYPES
 */
const GET_DEVICE = 'get device';
const GET_DEVICE_SUCCESS = 'get device success';
const GET_DEVICE_FAILURE = 'get device failure';
const GET_DEVICES = 'get devices';
const CANCEL_SUBMIT_DEVICE = 'cancel submit device';
const CANCEL_SUBMIT_PLAYLIST = 'cancel submit playlists';
const SUBMIT_DEVICE = 'submit device';
const SUBMIT_PLAYLIST = 'submit playlist';
const GET_VIDEOS = 'get videos';
const GET_PLAYLISTS = 'get playlists';
const GET_PLAYLIST = 'get playlist';
const GET_PLAYLIST_SUCCESS = 'get playlist success';
const GET_PLAYLIST_FAILURE = 'get playlist failure';
const LOGIN = 'login';
const LOGOUT = 'logout';

/**
 * ACTION CREATORS
 */
export const getDevice = () => ({ type: GET_DEVICE });
export const getDeviceSuccess = (device) => ({ type: GET_DEVICE_SUCCESS, device });
export const getDeviceFailure = (errorHeader, errorMessage) => ({ type: GET_DEVICE_FAILURE, messageHeader: errorHeader, messageBody: errorMessage });
export const getDevices = (devices) => ({ type: GET_DEVICES, devices });
export const cancelSubmitDevice = () => ({ type: CANCEL_SUBMIT_DEVICE });
export const cancelSubmitPlaylist = () => ({ type: CANCEL_SUBMIT_PLAYLIST });
export const submitDevice = (status, message) => ({ type: SUBMIT_DEVICE, status: status, message: message })
export const submitPlaylist = (status, message) => ({ type: SUBMIT_PLAYLIST, status: status, message: message })
export const getPlaylist = () => ({ type: GET_PLAYLIST });
export const getPlaylistSuccess = (playlist) => ({ type: GET_PLAYLIST_SUCCESS, playlist });
export const getPlaylistFailure = (errorHeader, errorMessage) => ({ type: GET_PLAYLIST_FAILURE, messageHeader: errorHeader, messageBody: errorMessage });
export const getPlaylists = (playlists) => ({ type: GET_PLAYLISTS, playlists })
export const getVideos = (videos) => ({ type: GET_VIDEOS, videos })
export const login = (user) => ({
  type: LOGIN,
  user
});

export const logout = () => ({
  type: LOGOUT
});

/**
 * Device Related actions
 */
export function getDevicesThunk() {
  return dispatch => {
    const devices = [];
    database.ref(`devices`).once('value', snap => {
      snap.forEach(data => {
        let device = data.val();
        devices.push(device)
      })
    })
      .then(() => dispatch(getDevices(devices)))
      .catch((err) => console.log('Error retrieving CMS database contents: ' + err))
  }
}

export function deviceDatabaseChangedEvent(dispatch) {
  return dispatch => {
    database.ref(`devices`).on('value', () => {
      dispatch(getDevicesThunk());
    });
  }
}

export function deleteDevice(key) {
  return dispatch => {
    database.ref('devices').child(key).remove();
    dispatch(getDevicesThunk());
  }
}

export function getSingleDevice(key) {
  //Check if the whitelist/claims have been changed on the backend
  return dispatch => {
    dispatch(getDevice())
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.devicesEndpoint + '?key=' + key, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          }
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(response => dispatch(getDeviceSuccess(response)))
      .catch(error => {
        console.log('Retrieve device failed!', error)
        dispatch(getDeviceFailure('Retrieve device failed', error.message));
      });
  }
}

export function createSingleDevice(device) {
  return dispatch => {
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.devicesEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          },
          body: JSON.stringify({
            device: device,
          })
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(() => dispatch(submitDevice('success', 'Device created successfully.')))
      .catch(error => {
        console.log('Create device failed!', error);
        dispatch(submitDevice('error', error.message));
      });
  }
}

export function updateSingleDevice(device) {
  return dispatch => {
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.devicesEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          },
          body: JSON.stringify({
            device: device,
          })
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(() => dispatch(submitDevice('success', 'Device updated successfully.')))
      .catch(error => {
        console.log('Update device failed!', error);
        dispatch(submitDevice('error', error.message));
      });
  }
}

/**
 * Video Related actions
 */
export function getVideosThunk() {
  return dispatch => {
    const videos = [];
    database.ref(`videos`).once('value', snap => {
      snap.forEach(data => {
        let video = data.val();
        videos.push(video)
      })
    })
      .then(() => dispatch(getVideos(videos)))
      .catch((err) => console.log('Error retrieving CMS database contents: ' + err))
  }
}


export function videoDatabaseChangedEvent(dispatch) {
  return dispatch => {
    database.ref(`videos`).on('value', () => {
      dispatch(getVideosThunk());
    });
  }
}

/**
 * Playlist related actions
 * The single playlist actions use a rest API instead of interacting directly with the DB
 * because there is some business logic involved.
 * 
 */
export function getPlaylistsThunk() {
  return dispatch => {
    const playlists = [];
    database.ref(`playlists`).once('value', snap => {
      snap.forEach(data => {
        let playlist = data.val();
        playlists.push(playlist)
      })
    })
      .then(() => dispatch(getPlaylists(playlists)))
      .catch((err) => console.log('Error retrieving CMS database contents: ' + err))
  }
}

export function deletePlaylist(key) {
  return dispatch => {
    database.ref('playlists').child(key).remove();
    dispatch(getPlaylistsThunk());
  }
}

export function getSinglePlaylist(key) {
  //Check if the whitelist/claims have been changed on the backend
  return dispatch => {
    dispatch(getPlaylist());
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.playlistsEndpoint + '?key=' + key, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          }
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(response => dispatch(getPlaylistSuccess(response)))
      .catch(error => {
        console.log('Retrieve playlist failed!', error);
        dispatch(getPlaylistFailure('Retrieve playlist failed!', error.message));
      });
  }
}

export function updateSinglePlaylist(playlist) {
  return dispatch => {
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.playlistsEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          },
          body: JSON.stringify({
            playlist: playlist,
          })
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(() => dispatch(submitPlaylist('success', 'Playlist updated successfully.')))
      .catch(error => {
        console.log('Update playlist failed!', error);
        dispatch(submitPlaylist('error', error.message));
      });
  }
}

export function createSinglePlaylist(playlist) {
  return dispatch => {
    firebaseApp.auth().currentUser.getIdToken()
      .then((idToken) => {
        return fetch(config.playlistsEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          },
          body: JSON.stringify({
            playlist: playlist,
          })
        })
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          return response.json()
            .then(json => {
              var error = new Error(json.statusMessage)
              error.response = response
              throw error;
            })
        }
      })
      .then(() => dispatch(submitPlaylist('success', 'Playlist created successfully.')))
      .catch(error => {
        console.log('Create playlist failed!', error);
        dispatch(submitPlaylist('error', error.message));
      });
  }
}

export function playlistDatabaseChangedEvent(dispatch) {
  return dispatch => {
    database.ref(`playlists`).on('value', () => {
      dispatch(getPlaylistsThunk());
    });
  }
}

/**
 * AUTH related actions
 */

export function firebaseLogin() {
  return function () {
    firebaseApp.auth().signInWithPopup(googleAuthProvider);
  }
}

export function firebaseLogout() {
  return function () {
    firebaseApp.auth().signOut();
  }
}

/**
 * REDUCER
 */
function Reducer(state = [], action) {
  switch (action.type) {
    case SUBMIT_DEVICE:
      return Object.assign({}, state, {
        status: action.status,
        message: action.message,
        currentDevice: undefined
      })
    case GET_DEVICE:
      return Object.assign({}, state, {
        loading: true
      })
    case GET_DEVICE_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        currentDevice: action.device
      })
    }
    case GET_DEVICE_FAILURE: {
      return Object.assign({}, state, {
        loading: false,
        error: true,
        messageHeader: action.messageHeader,
        messageBody: action.messageBody,
      })
    }
    case GET_DEVICES: {
      return Object.assign({}, state, {
        devices: action.devices
      })
    }
    case CANCEL_SUBMIT_DEVICE:
      return Object.assign({}, state, {
        currentDevice: undefined,
        status: undefined,
        message: undefined
      })
    case CANCEL_SUBMIT_PLAYLIST:
      return Object.assign({}, state, {
        currentPlaylist: undefined,
        status: undefined,
        message: undefined
      })
    case SUBMIT_PLAYLIST:
      return Object.assign({}, state, {
        status: action.status,
        message: action.message,
        currentPlaylist: undefined
      })
    case GET_PLAYLIST:
      return Object.assign({}, state, {
        loading: true
      })
    case GET_PLAYLIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        currentPlaylist: action.playlist
      })
    case GET_PLAYLIST_FAILURE: {
      return Object.assign({}, state, {
        loading: false,
        error: true,
        messageHeader: action.messageHeader,
        messageBody: action.messageBody,
      })
    }
    case GET_PLAYLISTS:
      return Object.assign({}, state, {
        playlists: action.playlists
      })
    case GET_VIDEOS:
      return Object.assign({}, state, {
        videos: action.videos
      })
    case LOGIN:
      return Object.assign({}, state, {
        user: action.user
      })
    case LOGOUT:
      state = INTIAL_STATE;
      return state
    default:
      return state
  }
}

export default createStore(Reducer, INTIAL_STATE, applyMiddleware(thunkMiddleware))
